import React, { useState, useRef } from "react";
import "./CSS/Initiatives.css";
import axios from "axios";
import { HOST_NAME } from "./const";
import Cookies from "js-cookie";

import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

const Initiatives = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  const tabs = [
    "Physiological Health",
    "Spiritual Health",
    "Financial Health",
    "Career Health",
    "Business Health",
    "Digital & Tech Health",
  ];

  const content = [
    {
      lever: [
        {
          initiativename: "Expectation Alignment Huddles",
          levername: "Communication",
          description:
            "Implement brief, bi-weekly “Expectation Alignment Huddles” where managers and their teams discuss current priorities, clarify roles, and adjust expectations as needed. These huddles are an opportunity to confirm that everyone is aligned on goals and individual responsibilities.Managers actively encourage questions, ensuring that all employees have a clear understanding of their tasks and can voice any concerns.",
          outcome:
            "Increased Role Clarity, Improved Team Cohesion, Higher Productivity",
        },
        {
          initiativename: "Goals and Wins Tracker",
          levername: "Communication",
          description:
            "Introduce a shared digital “Goals and Wins Tracker” where each team member can log their goals, tasks, and achievements weekly. Managers can review and provide feedback, helping to clarify expectations in real time. At the end of each month, the team celebrates achievements and discusses any challenges faced, reinforcing clear expectations and aligning goals",
          outcome:
            "Enhanced Accountability, Better Goal Alignment, Increased Recognition",
        },
        {
          initiativename: "Expectation Roadmaps for Each Role",
          levername: "Communication",
          description:
            "Develop an Expectation Roadmap for each role, outlining key responsibilities, goals, and skills required for success in the role. Managers review the roadmap with employees each quarter, making adjustments based on changing priorities and individual growth. The roadmap serves as a guiding document and is updated as employees progress in their roles, providing both structure and flexibility.",
          outcome:
            "Clear Role Definition, Structured Career Pathways, Reduced Miscommunication",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Commitment Tracker for Management Promises",
          levername: "Integrity",
          description:
            "Implement a “Commitment Tracker” where managers and team leads document promises made to employees (e.g., career development opportunities, project assignments). This tracker is visible to the team and is reviewed regularly to ensure follow-through. Managers publicly share updates on each commitment, allowing employees to see progress and hold leaders accountable.",
          outcome:
            "Increased Trust in Leadership, Higher Accountability, Improved Transparency",
        },
        {
          initiativename: "Quarterly Leadership Check-Ins",
          levername: "Integrity",
          description:
            "Schedule quarterly “Leadership Check-Ins” where senior management meets with small groups of employees to review progress on commitments made at the start of each quarter. This could include organizational goals, specific promises to the team, or personal growth opportunities.These check-ins allow leaders to communicate directly with employees about progress, challenges, and timelines, creating a feedback loop for continuous improvement.",
          outcome:
            "Strengthened Manager-Employee Relations, Enhanced Accountability Culture, Employee Empowerment",
        },
        {
          initiativename: "Promise-to-Action Dashboard",
          levername: "Integrity",
          description:
            "Develop a Promise-to-Action dashboard that highlights key commitments from management, with clear timelines and current status (e.g., “In Progress,” “Completed”). This dashboard is accessible to all employees, ensuring transparency around management’s follow-through. Managers are encouraged to update the dashboard weekly, with team discussions on any delays or adjustments needed to maintain realistic timelines.",
          outcome:
            "Enhanced Transparency and Trust, Reduced Frustration and Mistrust, Empowered Workforce",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Personal Day Passport Program",
          levername: "Caring",
          description:
            "Implement a Personal Day Passport initiative where employees receive a set number of “passport days” that they can use throughout the year for personal activities unrelated to work (e.g., family time, hobbies, self-care). Managers encourage using these days without needing justification, showing respect for personal time and promoting a healthy balance.",
          outcome:
            "Increased Employee Satisfaction, Reduced Burnout Rates, Higher Retention Rates",
        },
        {
          initiativename: "Work-Life Flex Block Scheduling",
          levername: "Caring",
          description:
            "Introduce a Flex Block system where employees can choose one day a month to adjust their hours according to personal needs (e.g., late start, early finish, extended lunch for family activities). This shows the organization’s commitment to flexibility and allows employees to tend to personal life while staying productive.",
          outcome:
            "Enhanced Work-Life Harmony, Increased Productivity, Stronger Trust in Management",
        },
        {
          initiativename: "Quarterly Life Beyond Work Showcase",
          levername: "Caring",
          description:
            "Host quarterly gatherings where employees can share their hobbies, passions, or personal accomplishments (e.g., cooking, art, sports). This could be a casual event, either virtual or in-person, that fosters respect for personal life interests and helps team members connect beyond work roles. Managers participate and celebrate employees’ talents and passions, promoting a culture of balance and appreciation.",
          outcome:
            "Stronger Interpersonal Connections,Increased Sense of Community, Positive Work Culture",
        },
        {
          initiativename: "Passion Projects Time Allocation",
          levername: "Caring",
          description:
            "Allocate a few hours every quarter for employees to work on a passion project, unrelated to their regular duties, that excites them. This could range from charity initiatives to creative or learning projects. Managers can check in, show interest, and offer support, demonstrating that the company values employees beyond just their work output.",
          outcome:
            "Enhanced Innovation and Creativity, Improved Job Satisfaction, Increased Loyalty",
        },
        {
          initiativename: "Interest-Based Mentorship Program",
          levername: "Caring",
          description:
            "Instead of traditional work-focused mentorship, offer an optional “Interest-Based Mentorship” where employees are paired based on shared interests or personal goals, like wellness, financial planning, or hobbies. This allows employees to connect on a personal level and encourages managers and leaders to engage with employees about their personal aspirations.",
          outcome:
            "Deeper Employee Engagement, Improved Knowledge Sharing, Enhanced Sense of Belonging",
        },
        {
          initiativename: "Storytime with Leaders Sessions",
          levername: "Caring",
          description:
            "Host monthly “Storytime with Leaders” where company leaders or managers host informal sessions and share personal journeys, challenges, and interests outside of work. Employees are encouraged to share as well, and the sessions create a two-way interaction. It helps foster a culture where employees feel valued as individuals, not just workers.",
          outcome:
            "Stronger Connection to Leadership, Open Communication Culture, Increased Morale and Trust",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Role Reversal Days",
          levername: "Equity",
          description:
            "Host quarterly Role Reversal Days, where employees at all levels can shadow roles outside their own, including leadership roles. This fosters understanding of responsibilities across the organization and breaks hierarchical barriers. Include a reverse mentorship element where junior employees provide feedback or insights to senior staff, emphasizing that every perspective matters.",
          outcome:
            "Increased Inclusion, Improved Empathy Across Roles, Enhanced Engagement",
        },
        {
          initiativename: "All-Hands Equal Spotlight",
          levername: "Equity",
          description:
            "During company-wide meetings, spotlight achievements from employees across all levels (e.g., from interns to senior leaders). Recognize contributions based on impact, not position, ensuring everyone has a platform for recognition.",
          outcome:
            "Boosted Morale, Flattened Hierarchies, Greater Collaboration",
        },
        {
          initiativename: "Inclusion Buddies Program",
          levername: "Equity",
          description:
            "Pair employees from different departments, levels, or roles as Inclusion Buddies to collaborate on a shared project or initiative outside their core responsibilities. Buddies work together, ensuring everyone feels connected and valued across the organization.",
          outcome:
            "Stronger Interpersonal Bonds, Increased Belonging, Diverse Perspectives",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Equity Advocacy Council",
          levername: "Justice",
          description:
            "Establish an Equity Advocacy Council comprising employees from diverse backgrounds and roles to monitor and advocate for fairness in hiring, promotions, pay, and policies. The council conducts regular audits and shares findings with leadership to drive continuous improvement.",
          outcome: "Fairer Policies, Greater Trust in Leadership, Reduced Bias",
        },
        {
          initiativename: "Bias Busters Workshops",
          levername: "Justice",
          description:
            "Conduct engaging, interactive Bias Busters Workshops to raise awareness of unconscious biases. Use gamified activities, real-world case studies, and anonymous employee scenarios to teach inclusive behavior and decision-making.",
          outcome:
            "Improved Awareness, More Inclusive Culture, Higher Trust Among Employees",
        },
        {
          initiativename: "Fairness Scorecards",
          levername: "Justice",
          description:
            "Introduce anonymous “Fairness Scorecards” where employees rate their experiences of equity and inclusion in their teams. Managers review these scores monthly and take specific actions to address gaps (e.g., adjusting workloads, training, or addressing unconscious biases).",
          outcome:
            "Greater Transparency, Higher Equity Scores, Stronger Leadership Accountability:",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Impact Spotlight Stories",
          levername: "Team",
          description:
            "Highlight team or individual achievements through Impact Spotlight Stories shared in town halls, newsletters, or on company intranets. Focus on how their work has made a tangible impact on customers, the community, or the company.",
          outcome:
            "Increased Employee Pride, Stronger Team Morale, Higher Engagement:",
        },
        {
          initiativename: "Achievement Wall of Fame",
          levername: "Team",
          description:
            "Create a physical or digital “Wall of Fame” showcasing top accomplishments, milestones, and projects completed by teams or individuals. Include a rotating “Employee Pride Moment” where employees share personal reflections on what makes them proud at work.",
          outcome:
            "Improved Recognition, Enhanced Sense of Ownership, Positive Culture Reinforcement",
        },
        {
          initiativename: "Mission Impact Day",
          levername: "Team",
          description:
            "Organize a quarterly “Mission Impact Day” where employees get updates on the organization’s key accomplishments and contributions, linking their daily efforts to the broader mission.",
          outcome:
            "Stronger Connection to Purpose, Increased Motivation, Improved Retention",
        },
        {
          initiativename: "Team Challenge Days",
          levername: "Team",
          description:
            "Host monthly Team Challenge Days, where departments work together to solve real company challenges or improve processes. Teams receive recognition for innovative ideas and collaborative problem-solving.",
          outcome:
            "Increased Collaboration, Stronger Work Ethic, Higher Productivity",
        },
        {
          initiativename: "Extra Mile Rewards Program",
          levername: "Team",
          description:
            "Launch an “Extra Mile Rewards Program” that allows employees to nominate peers who go above and beyond. Winners receive points redeemable for perks, such as professional development credits, extra PTO, or small gifts.",
          outcome:
            "Higher Motivation, Boosted Engagement, Improved Peer Relationships",
        },
        {
          initiativename: "Project Ownership Pods",
          levername: "Team",
          description:
            "Establish small cross-functional “Project Ownership Pods” that allow employees to take the lead on key initiatives, from planning to execution. Teams are empowered to make decisions and innovate without managerial micromanagement.",
          outcome:
            "Increased Ownership, Enhanced Collaboration, Better Results",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Career Customization Plans",
          levername: "Personal Job",
          description:
            "Create “Career Customization Plans,” allowing employees to co-design their growth path with managers, choosing between technical, leadership, or lateral growth tracks. Include periodic reviews to align progress with goals.",
          outcome:
            "Clearer Growth Pathways, Higher Engagement,Improved Skill Development",
        },
        {
          initiativename: "Stretch Assignment Marketplace",
          levername: "Personal Job",
          description:
            "Launch a “Stretch Assignment Marketplace” where employees can volunteer for short-term projects in different departments. This enables them to develop new skills and gain exposure to diverse areas of the business.",
          outcome:
            "Broader Skill Sets, Stronger Internal Mobility, Enhanced Engagement",
        },
        {
          initiativename: "Leadership Incubator Program",
          levername: "Personal Job",
          description:
            "Create a “Leadership Incubator Program” for aspiring leaders, offering targeted workshops, mentorship, and experiential learning to prepare them for leadership roles.",
          outcome:
            "Stronger Succession Planning, Higher Retention of Talent, Enhanced Morale",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Employee Advocacy Program",
          levername: "Corporate Image",
          description:
            "Train and incentivize employees to share positive workplace stories on social media or Glassdoor. Introduce a referral rewards program that recognizes employees who help bring in top talent.",
          outcome:
            "Improved Employer Branding, Increased Pride, Stronger Recruitment Pipelines",
        },
        {
          initiativename: "Pulse Surveys with Actionable Follow-Through",
          levername: "Corporate Image",
          description:
            "Conduct frequent, short pulse surveys to gather employee feedback on workplace improvements. Follow through on suggestions with visible actions to demonstrate responsiveness.",
          outcome: "Higher Advocacy Rates, Improved Morale, Better Retention.",
        },
        {
          initiativename: "Brand Ambassador Club",
          levername: "Corporate Image",
          description:
            "Create a “Brand Ambassador Club” where employees represent the company in external events, conferences, or hiring fairs. Ambassadors are recognized and rewarded for promoting the company culture.",
          outcome:
            "Stronger Employer Reputation, Boosted Employee Morale, Improved Recruitment",
        },
        {
          initiativename: "Daily Kickstart Huddles",
          levername: "Corporate Image",
          description:
            "Begin each workday with a 10-minute energizing huddle to share team wins, inspirational stories, or fun activities. Include voluntary participation to create a positive start to the day.",
          outcome:
            "Improved Team Energy, Increased Excitement, Stronger Team Bonding",
        },
        {
          initiativename: "Workplace Happiness Fund",
          levername: "Corporate Image",
          description:
            "Allocate a small monthly budget for teams to spend on fun activities, team lunches, or wellness perks chosen by team consensus. This brings excitement to everyday work.",
          outcome:
            "Boosted Morale, Higher Job Satisfaction, Stronger Workplace Culture.",
        },
        {
          initiativename: "Passion Projects Fridays",
          levername: "Corporate Image",
          description:
            "Dedicate one Friday a month for employees to work on passion projects, creative ideas, or initiatives unrelated to their core roles.",
          outcome: "Enhanced Creativity,Greater Engagement,Improved Innovation",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Buddy Connect Program",
          levername: "Intimacy",
          description:
            "Pair employees with a buddy from another department for a month-long connection program. The program includes informal chats, shared coffee breaks, and opportunities to work together on a small cross-functional task. Rotate buddies quarterly to encourage deeper friendships across the organization",
          outcome:
            "Enhanced Friendships, Improved Collaboration, Stronger Workplace Bonding",
        },
        {
          initiativename: "Friendship Recognition Awards",
          levername: "Intimacy",
          description:
            "Introduce an award recognizing Friendship Champions – employees who consistently bring people together or are known for their friendly demeanor. Employees nominate peers, and winners are celebrated in team meetings.",
          outcome:
            "Improved Team Dynamics, Increased Recognition, Greater Inclusivity",
        },
        {
          initiativename: "Team Trivia Bonding Nights",
          levername: "Intimacy",
          description:
            "Host monthly trivia or game nights where employees form random teams to answer fun questions about the organization, pop culture, or team facts. Ensure a mix of employees across departments to encourage new connections",
          outcome:
            "Increased Interaction, Stronger Relationships, Fun Workplace Environment",
        },
        {
          initiativename: "Care Packages for Employees",
          levername: "Intimacy",
          description:
            "Offer periodic “Care Packages” that include wellness items, snacks, handwritten thank-you notes, and stress-relief tools (e.g., journals or gift cards). Personalize the packages based on employee preferences to show genuine care.",
          outcome:
            "Improved Morale, Strengthened Loyalty, Positive Brand Perception",
        },
        {
          initiativename: "Employee Wellness Days",
          levername: "Intimacy",
          description:
            "Dedicate one day per quarter to wellness activities, offering free yoga, meditation sessions, or wellness workshops. Allow employees to take the day off if they prefer personal self-care.",
          outcome:
            "Reduced Burnout, Increased Engagement, Better Work-Life Balance",
        },
        {
          initiativename: "Personal Check-Ins by Leaders",
          levername: "Intimacy",
          description:
            "Train managers to conduct monthly one-on-one personal check-ins focusing on employee well-being rather than work performance. Encourage leaders to ask about personal challenges, aspirations, and areas of concern.",
          outcome:
            "Stronger Manager-Employee Relationships, Improved Well-being, Higher Retention Rates",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Togetherness Projects",
          levername: "Community",
          description:
            "Launch organization-wide projects, such as volunteering initiatives, hackathons, or charity fundraisers, where employees collaborate to achieve shared goals. For example, a day spent improving community spaces or building care kits for underprivileged groups.",
          outcome:
            "Stronger Team Spirit, Improved Employee Bonding, Enhanced Organizational Pride",
        },
        {
          initiativename: "Unity Day Celebrations",
          levername: "Community",
          description:
            "Host an annual Unity Day where employees wear team colors, participate in group activities (like tug-of-war or scavenger hunts), and enjoy shared meals. Include employee storytelling sessions to build connections.",
          outcome:
            "Improved Relationships, Boosted Morale, Increased Engagement",
        },
        {
          initiativename: "Coffee Corners",
          levername: "Community",
          description:
            "Create informal Coffee Corners where employees can gather for scheduled or impromptu conversations. Encourage team leaders to host bi-weekly coffee chats where teams can discuss non-work-related topics.",
          outcome:
            "Fostered Togetherness, Stronger Relationships, Fun Work Atmosphere",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Surprise Theme Days",
          levername: "Hospitality",
          description:
            "Introduce surprise theme days (e.g., 'Retro Day,' 'Superhero Day,' or 'Twinning Day') where employees dress up and participate in theme-based activities. Announce themes only a day in advance to keep excitement high.",
          outcome: "Increased Enjoyment, Improved Team Spirit, Boosted Morale",
        },
        {
          initiativename: "Fun@Work Committee",
          levername: "Hospitality",
          description:
            "Form a “Fun@Work Committee” of volunteers to organize regular fun events, like talent shows, trivia contests, or bake-offs. Rotate committee members quarterly to include diverse perspectives and ideas.",
          outcome:
            "Better Engagement, More Creative Work Environment, Stronger Workplace Community:",
        },
        {
          initiativename: "Flash Fun Challenges",
          levername: "Hospitality",
          description:
            "Surprise employees with quick, office-wide fun challenges like desk-decor competitions, impromptu dance-offs, or mini scavenger hunts during work hours. Winners receive small, thoughtful prizes.",
          outcome:
            "Elevated Energy Levels, Enhanced Employee Satisfaction, Stronger Team Bonding",
        },
      ],
    },
  ];

  // const content = [
  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Healthy",
  //         levername: "Physiological Health",
  //         description:
  //           "Initiative proposes a multifaceted approach to support employees' physiological health through the integration of wellbeing apps, collective wellbeing practices, and wellbeing-linked rewards. The initiative aims to promote habit formation and overall health, reinforce physical wellbeing through community activities, and align rewards with health-promoting experiences and services. This holistic approach ensures that employees have the resources, support, and incentives needed to maintain and improve their physical and mental health.",
  //         outcome:
  //           "Employees would develop healthier habits, leading to improved physical and mental wellbeing, reduced stress levels, and enhanced overall quality of life.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Become Fit",
  //         levername: "Physiological Health",
  //         description:
  //           "Wellbeing Initiative proposes enhancing employees' physical health by promoting regular exercise, raising awareness of physical wellbeing, and offering resources and programs dedicated to wellness. This initiative involves collaborating with professional physical trainers, offering diverse fitness activities, sharing informative materials, initiating workplace movement challenges, advocating for regular breaks, scheduling medical checkups, providing access to health screenings, organizing sports events, and establishing a company sports club.",
  //         outcome:
  //           "Encouraging regular physical activity boosts energy levels, enhances productivity, promotes healthy lifestyle choices, and contributes to a more active workforce. ",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "High",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Comes Together",
  //         levername: "Spiritual Health",
  //         description:
  //           "The 'Become Comes Together' initiative aims to strengthen employees' connection to the company’s mission by organizing community service events and social impact projects. This initiative will involve partnerships with local non-profits and social organizations to create ongoing volunteering opportunities. Additionally, a platform will be created for employees to propose and lead their own social impact projects, with resources and support provided by the company.",
  //         outcome:
  //           "Increased employee fulfillment, stronger alignment with the Become’s mission, enhanced community impact, and improved employee morale. Employees will feel more connected to the company’s mission and values, leading to higher engagement and satisfaction.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Become Volunteering Time Off (VTO",
  //         levername: "Spiritual Health",
  //         description:
  //           "This initiative provides employees with paid time off to volunteer for social causes they are passionate about. Employees can choose from a list of approved non-profits or propose their own volunteering opportunities. The initiative includes a comprehensive policy outlining the program’s vision, objectives, and scope. Employees are required to submit a report detailing their contributions and experiences after their volunteering activities. The program aims to foster a sense of purpose, enhance community engagement, and support the company’s mission of societal impact.",
  //         outcome:
  //           "Employees will feel more connected to the Become’s mission, leading to increased fulfillment and morale. The initiative will promote a culture of giving back and community involvement, enhancing the company's reputation and employee satisfaction. Additionally, it will support personal growth and development through diverse volunteering experiences.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Financially Secure",
  //         levername: "Financial Health",
  //         description:
  //           "Finance Drives will provide employees with access to better financial wellbeing through targeted support in four key areas: insurance, savings, investment, and financial management. This initiative will include monthly workshops conducted by financial experts who will provide practical advice and actionable steps. Employees will also receive resource materials such as brochures, guides, and online resources to support their financial decisions. Additionally, one-on-one consultations with financial advisors will be available quarterly to offer personalized guidance based on individual financial needs.",
  //         outcome:
  //           "Employees will have better access to information and tools to improve their financial health, leading to reduced financial stress, increased financial security, and enhanced overall wellbeing.",
  //         duration: "6 month",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Become Financially Literate",
  //         levername: "Financial Health",
  //         description:
  //           "Awareness and Specialty Sessions will focus on improving employees' financial awareness and management skills through a series of comprehensive education sessions. These sessions will cover essential topics such as budgeting, saving, investing, and emergency fund preparation. Monthly group workshops will encourage interactive learning and peer support, while quarterly one-on-one sessions will provide personalized financial advice tailored to individual employees' needs. The content will be customized based on the average salary slabs within the company to ensure relevance and applicability. Practical exercises and real-life examples will be included to enhance learning outcomes.",
  //         outcome:
  //           "Employees will gain better financial management skills, be better prepared for financial emergencies, and improve their savings practices. This will lead to improved financial health, reduced financial stress, increased employee satisfaction, and higher retention rates.",
  //         duration: "6 months",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },

  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Recognised",
  //         levername: "Career Health",
  //         description:
  //           "Rewards & Recognition Program will focus on rewarding and recognising employees' efforts, behaviours, values, contributions, personal and professional achievements, and performances. This initiative will include monthly awards, spot bonuses, and public recognition in company meetings and newsletters. Employees will be nominated by their peers and managers, and a committee will evaluate the nominations to ensure fairness and consistency. Additional perks such as extra paid leave days, gift vouchers, and professional development opportunities will also be included.",
  //         outcome:
  //           "Employees will feel valued and appreciated, leading to increased motivation, job satisfaction, and loyalty. This will foster a positive work environment and enhance overall performance and productivity.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },

  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Gamified",
  //         levername: "Business Health",
  //         description:
  //           "Gamified Performance Programs proposes using gamification techniques to build a comprehensive performance program within the organisation. This initiative will involve designing interactive and engaging methods to track and enhance employee performance. Elements such as points, badges, leaderboards, and rewards will be incorporated to motivate employees and promote healthy competition. Regular feedback and performance reviews will be gamified to ensure continuous improvement and engagement.",
  //         outcome:
  //           "Employees will be more motivated and engaged, leading to improved performance, productivity, and job satisfaction.",
  //         duration: "6-12 months",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Become Streamlined",
  //         levername: "Business Health",
  //         description:
  //           "Systems and Processes Enhancement proposes conducting comprehensive reviews of existing systems and processes, implementing enhancements to streamline workflows, and increasing overall efficiency. Additional training for management to improve accessibility. Regular benchmarking of benefits will also be included to ensure competitiveness and employee satisfaction.",
  //         outcome:
  //           "The initiative will result in smoother workflows, increased efficiency, improved satisfaction, and enhanced overall business performance. Employees will benefit from clearer processes, and a better resource allocation",
  //         duration: "Ongoing monitoring and adjustments",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Advanced",
  //         levername: "Digital & Tech Health",
  //         description:
  //           "This initiative aims to enhance employees' physiological health by encouraging regular physical activity, increasing awareness of physical health, and providing resources and programs focused on well-being. The initiative will include fitness sessions with a physical trainer, health seminars, workplace movement challenges, medical checkups, and sports activities. Regular fitness sessions will be conducted by a professional trainer, covering various activities like yoga, strength training, and cardio exercises. Health seminars will raise awareness about the importance of physical health and provide tips on maintaining a healthy lifestyle. Workplace movement challenges and sports activities will encourage regular participation in physical activities.",
  //         outcome:
  //           "Improved physical health, higher energy levels, enhanced productivity, and better overall employee well-being. Employees will be more active and energetic, leading to a healthier, more productive workforce.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "High",
  //       },
  //     ],
  //   },
  // ];

  const scrollTabs = (direction) => {
    const scrollAmount = 200; // Adjust scroll amount as needed
    if (tabsRef.current) {
      tabsRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "50px",
        left: "18%",
        width: "65vw",
      }}
    >
      <div style={{ width: "60vw", backgroundColor: "#CFCFCF" }} />
      <p style={{ fontWeight: "510", fontSize: "20px" }}>Initiatives</p>
      <div
        style={{ width: "60vw", height: "1px", backgroundColor: "#CFCFCF" }}
      />

      {/* <button
          onClick={() => scrollTabs("left")}
          style={{
            backgroundColor: "#0042A6",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          &#8592; {/* Left arrow 
        </button> */}
      <div
        className="tabs"
        style={{
          width: "65vw",
          overflow: "auto",
        }}
        ref={tabsRef}
      >
        {content.map((tab, index) => (
          <div
            key={index}
            className={`tab-button ${activeTab === index ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#0042A6",
              borderRadius: "8px",
              width: "200px",
              height: "20px",
              margin: "10px",
              color: `${activeTab === index ? "#fff06b" : "#fff"}`,
              whiteSpace: "nowrap",
            }}
          >
            <p style={{ width: "100%", fontSize: "14px" }}>
              {tab.lever[0].levername}
            </p>
          </div>
        ))}
      </div>
      <div
        style={{
          width: "65vw",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{ cursor: "pointer", marginRight: "15px" }}
          onClick={() => scrollTabs("left")}
        >
          <MdKeyboardArrowLeft size={30} color="#000" />
        </div>
        <div style={{ cursor: "pointer" }} onClick={() => scrollTabs("right")}>
          <MdKeyboardArrowRight size={30} color="#000" />
        </div>
      </div>

      {/* <button
          onClick={() => scrollTabs("right")}
          style={{
            backgroundColor: "#0042A6",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          &#8594;
        </button> */}

      <div style={{ width: "62vw" }} className="tab-content">
        {content[activeTab].lever.map((lever, leverIndex) => (
          <div>
            {/* <div
                style={{
                    backgroundColor: "#fff06b",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  top: "-20px",
                }}
              > */}
            <p
              style={{
                fontWeight: "700",
                fontSize: "25px",
                color: "#0042A6",
              }}
            >
              {lever.initiativename}
            </p>
            {/* </div> */}
            <p style={{ fontWeight: "700" }}>What we can do?</p>
            <p style={{ marginTop: "-15px" }}>{lever.description}</p>
            <p style={{ fontWeight: "700" }}>
              What can you expect as an outcome?
            </p>
            <p style={{ marginTop: "-15px" }}>{lever.outcome}</p>

            {/* <div className="grid-view">
                <div className="grid-item">Duration: {lever.duration}</div>
                <div className="grid-item">Cost: {lever.cost}</div>
                <div className="grid-item">Impact: {lever.impact}</div>
                <div className="grid-item">Effort: {lever.effort}</div>
              </div> */}
            {content[activeTab].lever.length !== 0 &&
              leverIndex !== content[activeTab].lever.length - 1 && (
                <div
                  style={{
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "rgba(0,0,0,0.2)",
                    }}
                  />
                </div>
              )}
          </div>
        ))}
      </div>
    </div>
  );
};
//{content[activeTab]}

export default Initiatives;
