import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import { HOST_NAME } from "./const";

const SurveyProgress = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const [progressLink, setProgressLink] = useState();

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${HOST_NAME}/survey/get-organization`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setProgressLink(res.data.data.progresslink);
        })
        .catch((err) => console.log(err));
    };
    fetchData();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        width: "80vw",
        height: "100vh",
      }}
    >
      <div>
        <iframe
          title="Insight Viewer"
          src={progressLink}
          style={{ width: "80vw", height: "100vh" }}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default SurveyProgress;
