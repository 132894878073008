import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import "./Timeline.css";
import Cookies from "js-cookie";
import { HOST_NAME } from "./const";
import axios from "axios";

const HorizontalTimeline = () => {
  const [statuses, setStatuses] = useState([
    { label: "Pre launch", completed: true },
    { label: "Survey launched", completed: true },
    { label: "Survey completed", completed: true },
    { label: "Survey interpretation", completed: true },
    { label: "Report delivery", completed: true },
    // { label: "Certification delivery", completed: true },
  ]);
  const lastIncompleteIndex =
    statuses.findIndex((status) => !status.completed) - 1;

  const [status, setStatus] = useState();

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${HOST_NAME}/survey/get-organization`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setStatus(res.data.data.status);
          const index = statuses.findIndex(
            (status) => status.label === res.data.data.status
          );

          const updatedStatuses = statuses.map((status, i) => ({
            ...status,
            completed: i <= index,
          }));

          setStatuses(updatedStatuses);
        })
        .catch((err) => console.log(err));
    };
    fetchData();
  }, []);
  //${index >= lastIncompleteIndex ? "last-incomplete" : ""}
  return (
    <div className="timeline-container">
      {statuses.map((status, index) => (
        <div
          key={index}
          className={`timeline-item ${status.completed ? "completed" : ""} 
          
          `}
        >
          <div className="timeline-icon">
            {status.completed ? (
              <FaCheckCircle color="#0042a6" />
            ) : (
              <div className="circle" />
            )}
          </div>
          <div className="timeline-label">{status.label}</div>
          {index !== statuses.length - 1 && <div className="timeline-line" />}
        </div>
      ))}
    </div>
  );
};

export default HorizontalTimeline;
