// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  align-items: center;
}

#upload-sheet-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2961b6;
  color: #fff;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/screens/Aspire.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,0CAA0C;AAC5C","sourcesContent":[".container {\n  display: flex;\n  justify-content: center;\n  height: 100vh;\n  flex-direction: column;\n  align-items: center;\n}\n\n#upload-sheet-btn {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #2961b6;\n  color: #fff;\n  padding-left: 10px;\n  padding-right: 10px;\n  border-radius: 10px;\n  cursor: pointer;\n  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
