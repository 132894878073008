import axios from "axios";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { HOST_NAME } from "./const";
import StatusBar from "./StatusBar";

const Profile = () => {
  const [companyName, setCompanyName] = useState();
  const [logo, setLogo] = useState();
  const [industry, setIndustry] = useState();
  const [size, setSize] = useState();

  useEffect(() => {
    const fetchOrg = async () => {
      await axios
        .get(`${HOST_NAME}/survey/get-organization`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setCompanyName(res.data.data.name);
          setLogo(res.data.data.logo);
          setIndustry(res.data.data.industry);
          setSize(res.data.data.size);
        })
        .catch((err) => console.log(err));
    };

    fetchOrg();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        width: "60vw",
      }}
    >
      <StatusBar />
      <p style={{ fontWeight: "510", color: "#000", fontSize: "20px" }}>
        Organization Details
      </p>
      <div
        style={{
          width: "50vw",
          padding: "20px",
          boxShadow: "2px 2px 8px rgba(0,0,0,0.14)",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            alt=""
            style={{
              maxWidth: "30%",
              height: "auto",
              objectFit: "contain",
              padding: "5px",
              marginRight: "40px",
            }}
            src={logo}
          />
          <div>
            <p>
              <span>Company:</span> {companyName}
            </p>
            <p>
              <span>Industry:</span> {industry}
              <p>
                <span>Size:</span> Medium ({size} Employees)
              </p>
            </p>
          </div>
        </div>
      </div>

      <p style={{ fontWeight: "510", color: "#000", fontSize: "20px" }}>
        Contact
      </p>
      <div
        style={{
          width: "50vw",
          padding: "20px",
          boxShadow: "2px 2px 8px rgba(0,0,0,0.14)",
          borderRadius: "8px",
        }}
      >
        <div>
          <p>
            <span>Email:</span> letspartner@spigroup.in
          </p>
          <p>
            <span>Contact no:</span> +91 9445895581
          </p>
        </div>
      </div>
    </div>
  );
};

export default Profile;
