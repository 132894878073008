import React, { useState, useEffect } from "react";
import axios from "axios";
import { HOST_NAME } from "./const";
import Cookies from "js-cookie";

const Insights = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });

  const [analyticsLink, setAnalyticsLink] = useState("");
  //This is for showing analytics link
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${HOST_NAME}/survey/get-organization`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setAnalyticsLink(res.data.data.analyticsLink);
        })
        .catch((err) => console.log(err));
    };
    fetchData();
  }, []);

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      <div>
        <iframe
          title="Insight Viewer"
          src={analyticsLink}
          style={{ width: "80vw", height: "100vh" }}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Insights;
