export const HOST_NAME = "https://api.thrivingworkplace.in";
// export const HOST_NAME = "http://localhost:4000";
export const BELOW_80 = "#fff06b";
// const le =
// {
//   levers: {
//     "Physiological Health": [
//       {
//         inf: "Employees have strong confidence in the leadership team.",
//       },
//       {
//         inf: "Become’s brand is highly regarded internally.",
//       },
//       {
//         inf: "Benefits are generally viewed positively.",
//       },
//       {
//         inf: "There is a need to enhance skills and professional development.",
//       },
//     ],
//     "Cultural Health": [
//       {
//         inf: "Employees highly regard the values upheld by Become.",
//       },
//       {
//         inf: "The practices within Become are seen as effective and unique.",
//       },
//       {
//         inf: "Ethical standards are perceived as strong and well-maintained.",
//       },
//       {
//         inf: "Workplaces and environments provided by Become are viewed positively.",
//       },
//     ],
//   },
// };

// [
//   {
//     end: 12,
//     name: "Physiological Health",
//     start: 1,
//     category: [
//       { end: 3, name: "Wellbeing", start: 1 },
//       { end: 6, name: "Movement", start: 4 },
//       { end: 12, name: "Energy", start: 7 },
//     ],
//   },
//   {
//     end: 20,
//     name: "Spiritual Health",
//     start: 13,
//     category: [
//       { end: 16, name: "Purpose", start: 13 },
//       { end: 20, name: "Impact", start: 17 },
//     ],
//   },
//   {
//     end: 28,
//     name: "Financial Health",
//     start: 21,
//     category: [{ end: 28, name: "Status quo", start: 21 }],
//   },
//   {
//     end: 37,
//     name: "Intellectual Health",
//     start: 29,
//     category: [
//       { end: 31, name: "Learning", start: 29 },
//       { end: 33, name: "Innovation", start: 32 },
//       { end: 37, name: "Fitment", start: 34 },
//     ],
//   },
//   {
//     end: 47,
//     name: "Career Health",
//     start: 38,
//     category: [
//       { end: 40, name: "Recognition", start: 38 },
//       { end: 44, name: "Mentorship", start: 41 },
//       { end: 47, name: "Growth", start: 45 },
//     ],
//   },
//   {
//     end: 65,
//     name: "Business Health",
//     start: 48,
//     category: [
//       { end: 51, name: "Leadership", start: 48 },
//       { end: 55, name: "Brand", start: 52 },
//       { end: 60, name: "Benefits", start: 56 },
//       { end: 65, name: "Competence", start: 61 },
//     ],
//   },
//   {
//     end: 79,
//     name: "Cultural Health",
//     start: 66,
//     category: [
//       { end: 68, name: "Values", start: 66 },
//       { end: 71, name: "Practices", start: 69 },
//       { end: 76, name: "Ethics", start: 72 },
//       { end: 79, name: "Places", start: 77 },
//     ],
//   },
//   {
//     end: 89,
//     name: "Social Health",
//     start: 80,
//     category: [
//       { end: 82, name: "Collaboration", start: 80 },
//       { end: 89, name: "Community", start: 83 },
//     ],
//   },
//   {
//     end: 102,
//     name: "Mental Health",
//     start: 90,
//     category: [
//       { end: 94, name: "Motivation", start: 90 },
//       { end: 99, name: "Support", start: 95 },
//       { end: 102, name: "Expression", start: 100 },
//     ],
//   },
//   {
//     end: 107,
//     name: "Digital and Tech Health",
//     start: 103,
//     category: [
//       { end: 105, name: "Adeptness", start: 103 },
//       { end: 107, name: "Availability and Access", start: 106 },
//     ],
//   },
// ];
