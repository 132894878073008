import React, { useState, useRef } from "react";
import "./CSS/Initiatives.css";
import axios from "axios";
import { HOST_NAME } from "./const";
import Cookies from "js-cookie";

import { MdKeyboardArrowLeft } from "react-icons/md";
import { MdKeyboardArrowRight } from "react-icons/md";

const Initiatives = () => {
  const [activeTab, setActiveTab] = useState(0);
  const tabsRef = useRef(null);
  const tabs = [
    "Physiological Health",
    "Spiritual Health",
    "Financial Health",
    "Career Health",
    "Business Health",
    "Digital & Tech Health",
  ];

  const content = [
    {
      lever: [
        {
          initiativename: "Quarterly Feedback Syncs",
          levername: "Communication",
          description:
            "Implement structured quarterly one-on-one sessions where managers and employees discuss expectations, review progress, and adjust goals collaboratively.",
          outcome:
            "Improved communication and alignment on objectives, leading to fewer performance gaps and higher employee satisfaction.",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "'Promise Tracker' Transparency Board",
          levername: "Integrity",
          description:
            "Create a digital dashboard visible to all employees, where management commitments and their progress are tracked in real time. This board should display milestones, timelines, and updates on key promises.",
          outcome:
            "Employees gain trust in leadership as they see transparent progress on promises, fostering accountability and reducing skepticism.",
        },

        {
          initiativename: "Storytelling for Trust",
          levername: "Integrity",
          description:
            "Create internal communication campaigns, such as videos or newsletters, that highlight fulfilled promises and their impact on the organization. Include testimonials from employees to add authenticity.",
          outcome:
            "Employees connect emotionally with leadership’s efforts, fostering loyalty and reinforcing trust in management’s commitment to their promises.",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Life Swap Immersive Workshop",
          levername: "Caring",
          description:
            "Host immersive workshops where employees swap roles for a day (e.g., a leader shadows a parent’s daily routine) to build empathy for work-life balance challenges.",
          outcome:
            "Leadership gains a deeper understanding of employee challenges, leading to more supportive policies and practices.",
        },
        {
          initiativename: "Family-Friendly Work Events",
          levername: "Caring",
          description:
            "Host family-inclusive work events (e.g., movie nights or virtual game days) to allow employees to involve loved ones while maintaining a sense of community.",
          outcome:
            "Strengthens employee relationships and allows families to understand work culture, increasing overall satisfaction.",
        },
        {
          initiativename: "Internal Film Magazine",
          levername: "Caring",
          description:
            "A monthly in-house magazine featuring film industry insights, staff reviews, upcoming releases, and behind-the-scenes stories from Qube's projects, designed to inspire and engage employees passionate about cinema.",
          outcome:
            "Fosters a sense of connection and excitement about Qube's role in the film industry, while encouraging employee engagement and creativity.",
        },
        {
          initiativename: "Digital Detox Hours",
          levername: "Caring",
          description:
            "Designated hours where employees are encouraged to unplug from digital devices, fostering mindfulness, relaxation, and meaningful in-person interactions.",
          outcome:
            "Promotes mental well-being and builds stronger interpersonal relationships within the workplace.",
        },
        {
          initiativename: "Personal Storytelling Hour",
          levername: "Caring",
          description:
            "Create a forum in GEMS meeting where employees voluntarily share personal stories, passions, or achievements unrelated to work, supported by a no-judgment policy.",
          outcome:
            "Builds deeper connections between employees and leadership, fostering a culture of appreciation for individuality.",
        },
        {
          initiativename: "Interest Circles",
          levername: "Caring",
          description:
            "Facilitate small employee groups based on shared interests (e.g., book clubs, fitness challenges), supported with company-provided resources or meeting spaces.",
          outcome:
            "Encourages deeper connections among employees and shows management’s commitment to nurturing their passions.",
        },
        {
          initiativename: "Human Library",
          levername: "Caring",
          description:
            "Establish a 'human library' where employees act as books, sharing unique experiences or life lessons during short, informal sessions.",
          outcome:
            "Fosters understanding, empathy, and appreciation for the diverse experiences within the workforce.",
        },
        {
          initiativename: "Mentorship Programs",
          levername: "Caring",
          description:
            "A structured program where experienced employees mentor newer or junior team members, providing guidance, skill development, and career growth opportunities.",
          outcome:
            "Builds a culture of learning and support, fostering leadership and professional growth.",
        },
        {
          initiativename: "Humans of Qube",
          levername: "Caring",
          description:
            "A storytelling initiative showcasing inspiring and personal stories of Qube employees, highlighting their journeys, challenges, and achievements.",
          outcome:
            "Celebrates individuality, builds a sense of community, and fosters deeper connections among employees.",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Level-Free Collaboration Days",
          levername: "Equity",
          description:
            "Designate certain days for brainstorming or problem-solving sessions where employees from all levels work together without role or hierarchy constraints.",
          outcome:
            "Encourages a culture of inclusion and respect, making everyone feel valued for their ideas, not their job titles.",
        },
        {
          initiativename: "Quarterly Development Check-Ins",
          levername: "Equity",
          description:
            "Scheduled, one-on-one meetings between employees and managers every quarter to discuss performance, set goals, and provide actionable feedback.",
          outcome:
            "Aligns individual goals with organizational objectives and ensures continuous growth and alignment.",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Fairness Scorecards",
          levername: "Justice",
          description:
            "Introduce anonymous “Fairness Scorecards” where employees rate their experiences of equity and inclusion in their teams. Managers review these scores monthly and take specific actions to address gaps (e.g., adjusting workloads, training, or addressing unconscious biases).",
          outcome:
            "Greater Transparency, Higher Equity Scores, Stronger Leadership Accountability",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Milestone Awards Program",
          levername: "Team",
          description:
            "Introduce awards that recognize teams for specific achievements, such as completing major projects or hitting performance targets.",
          outcome:
            "Celebrates team accomplishments, reinforcing pride in work done well.",
        },
        {
          initiativename: "Why We Do It Insights",
          levername: "Team",
          description:
            "Share metrics or stories showing how the company’s work positively impacts clients, customers, or communities.",
          outcome:
            "Builds a sense of pride by connecting daily tasks to broader, meaningful results.",
        },
        {
          initiativename: "Mission Impact Day",
          levername: "Team",
          description:
            "Organize a quarterly “Mission Impact Day” where employees get updates on the organization’s key accomplishments and contributions, linking their daily efforts to the broader mission.",
          outcome:
            "Stronger Connection to Purpose, Increased Motivation, Improved Retention",
        },
        {
          initiativename: "Effort Benchmarking Metrics",
          levername: "Team",
          description:
            "Implement team or individual effort metrics, recognizing those consistently exceeding expectations.",
          outcome:
            "Reinforces a results-oriented culture, inspiring others to raise their efforts.",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Career Customization Plans",
          levername: "Personal Job",
          description:
            "Create 'Career Customization Plans,' allowing employees to co-design their growth path with managers, choosing between technical, leadership, or lateral growth tracks. Include periodic reviews to align progress with goals.",
          outcome:
            "Clearer Growth Pathways, Higher Engagement, Improved Skill Development",
        },
        {
          initiativename: "Hackathons for Innovation",
          levername: "Personal Job",
          description:
            "Host internal hackathons where employees can solve real business problems, providing exposure and leadership opportunities.",
          outcome:
            "Encourages innovation and showcases talent, opening doors for career advancement.",
        },
        {
          initiativename: "Micro-Learning Modules",
          levername: "Personal Job",
          description:
            "Bite-sized training sessions focused on key skills, delivered digitally or in-person, allowing employees to learn efficiently without disrupting their workflow.",
          outcome:
            "Builds a culture of continuous learning, equipping employees with relevant skills in a time-efficient manner.",
        },
        {
          initiativename: "Lunch n Learn",
          levername: "Personal Job",
          description:
            "Informal learning sessions during lunch hours where employees or guest speakers share insights, tips, or best practices on professional and personal topics.",
          outcome:
            "Promotes a culture of shared learning and community bonding in a relaxed setting.",
        },
        {
          initiativename: "Emerging Leaders Academy",
          levername: "Personal Job",
          description:
            "A leadership development program aimed at identifying and grooming high-potential employees for future managerial and leadership roles.",
          outcome:
            "Strengthens the organization’s leadership pipeline, ensuring long-term growth and stability.",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Employee Advocacy Program",
          levername: "Corporate Image",
          description:
            "Train and incentivize employees to share positive workplace stories on social media or Glassdoor. Introduce a referral rewards program that recognizes employees who help bring in top talent.",
          outcome:
            "Improved Employer Branding, Increased Pride, Stronger Recruitment Pipelines",
        },
        {
          initiativename: "Pulse Surveys with Actionable Follow-Through",
          levername: "Corporate Image",
          description:
            "Conduct frequent, short pulse surveys to gather employee feedback on workplace improvements. Follow through on suggestions with visible actions to demonstrate responsiveness.",
          outcome: "Higher Advocacy Rates, Improved Morale, Better Retention.",
        },
        {
          initiativename: "Brand Ambassador Club",
          levername: "Corporate Image",
          description:
            "Create a 'Brand Ambassador Club' where employees represent the company in external events, conferences, or hiring fairs. Ambassadors are recognized and rewarded for promoting the company culture.",
          outcome:
            "Stronger Employer Reputation, Boosted Employee Morale, Improved Recruitment",
        },
        {
          initiativename: "Daily Kickstart Huddles",
          levername: "Corporate Image",
          description:
            "Begin each workday with a 10-minute energizing huddle to share team wins, inspirational stories, or fun activities. Include voluntary participation to create a positive start to the day.",
          outcome:
            "Improved Team Energy, Increased Excitement, Stronger Team Bonding",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Buddy Connect Program",
          levername: "Intimacy",
          description:
            "Pair employees with a 'buddy' from another department for a month-long connection program. The program includes informal chats, shared coffee breaks, and opportunities to work together on a small cross-functional task. Rotate buddies quarterly to encourage deeper friendships across the organization.",
          outcome:
            "Enhanced Friendships, Improved Collaboration, Stronger Workplace Bonding",
        },
        {
          initiativename: "Peer Recognition Platforms",
          levername: "Intimacy",
          description:
            "A digital platform where employees can recognize and appreciate their peers for outstanding contributions, fostering a culture of acknowledgment and teamwork.",
          outcome:
            "Encourages a positive work environment by enhancing morale and promoting mutual respect among employees.",
        },
        {
          initiativename: "Team Trivia Bonding Nights",
          levername: "Intimacy",
          description:
            "Host monthly trivia or game nights where employees form random teams to answer fun questions about the organization, pop culture, or team facts. Ensure a mix of employees across departments to encourage new connections",
          outcome:
            "Increased Interaction, Stronger Relationships, Fun Workplace Environment",
        },
        {
          initiativename: "Employee Wellness Days",
          levername: "Intimacy",
          description:
            "Dedicate one day per quarter to wellness activities, offering free yoga, meditation sessions, or wellness workshops. Allow employees to take the day off if they prefer personal self-care.",
          outcome:
            "Reduced Burnout, Increased Engagement, Better Work-Life Balance",
        },
        {
          initiativename: "Personal Check-Ins by Leaders",
          levername: "Intimacy",
          description:
            "Train managers to conduct monthly one-on-one personal check-ins focusing on employee well-being rather than work performance. Encourage leaders to ask about personal challenges, aspirations, and areas of concern.",
          outcome:
            "Stronger Manager-Employee Relationships, Improved Well-being, Higher Retention Rates",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Coffee Corners",
          levername: "Community",
          description:
            "Create informal 'Coffee Corners' where employees can gather for scheduled or impromptu conversations. Encourage team leaders to host bi-weekly coffee chats where teams can discuss non-work-related topics.",
          outcome:
            "Fostered Togetherness, Stronger Relationships, Fun Work Atmosphere",
        },
      ],
    },
    {
      lever: [
        {
          initiativename: "Surprise Theme Days",
          levername: "Hospitality",
          description:
            "Introduce surprise theme days (e.g., 'Retro Day,' 'Superhero Day,' or 'Twinning Day') where employees dress up and participate in theme-based activities. Announce themes only a day in advance to keep excitement high.",
          outcome: "Increased Enjoyment, Improved Team Spirit, Boosted Morale",
        },
        {
          initiativename: "Fun@Work Committee",
          levername: "Hospitality",
          description:
            "Form a “Fun@Work Committee” of volunteers to organize regular fun events, like talent shows, trivia contests, or bake-offs. Rotate committee members quarterly to include diverse perspectives and ideas.",
          outcome:
            "Better Engagement, More Creative Work Environment, Stronger Workplace Community:",
        },
      ],
    },
  ];

  // const content = [
  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Healthy",
  //         levername: "Physiological Health",
  //         description:
  //           "Initiative proposes a multifaceted approach to support employees' physiological health through the integration of wellbeing apps, collective wellbeing practices, and wellbeing-linked rewards. The initiative aims to promote habit formation and overall health, reinforce physical wellbeing through community activities, and align rewards with health-promoting experiences and services. This holistic approach ensures that employees have the resources, support, and incentives needed to maintain and improve their physical and mental health.",
  //         outcome:
  //           "Employees would develop healthier habits, leading to improved physical and mental wellbeing, reduced stress levels, and enhanced overall quality of life.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Become Fit",
  //         levername: "Physiological Health",
  //         description:
  //           "Wellbeing Initiative proposes enhancing employees' physical health by promoting regular exercise, raising awareness of physical wellbeing, and offering resources and programs dedicated to wellness. This initiative involves collaborating with professional physical trainers, offering diverse fitness activities, sharing informative materials, initiating workplace movement challenges, advocating for regular breaks, scheduling medical checkups, providing access to health screenings, organizing sports events, and establishing a company sports club.",
  //         outcome:
  //           "Encouraging regular physical activity boosts energy levels, enhances productivity, promotes healthy lifestyle choices, and contributes to a more active workforce. ",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "High",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Comes Together",
  //         levername: "Spiritual Health",
  //         description:
  //           "The 'Become Comes Together' initiative aims to strengthen employees' connection to the company’s mission by organizing community service events and social impact projects. This initiative will involve partnerships with local non-profits and social organizations to create ongoing volunteering opportunities. Additionally, a platform will be created for employees to propose and lead their own social impact projects, with resources and support provided by the company.",
  //         outcome:
  //           "Increased employee fulfillment, stronger alignment with the Become’s mission, enhanced community impact, and improved employee morale. Employees will feel more connected to the company’s mission and values, leading to higher engagement and satisfaction.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Become Volunteering Time Off (VTO",
  //         levername: "Spiritual Health",
  //         description:
  //           "This initiative provides employees with paid time off to volunteer for social causes they are passionate about. Employees can choose from a list of approved non-profits or propose their own volunteering opportunities. The initiative includes a comprehensive policy outlining the program’s vision, objectives, and scope. Employees are required to submit a report detailing their contributions and experiences after their volunteering activities. The program aims to foster a sense of purpose, enhance community engagement, and support the company’s mission of societal impact.",
  //         outcome:
  //           "Employees will feel more connected to the Become’s mission, leading to increased fulfillment and morale. The initiative will promote a culture of giving back and community involvement, enhancing the company's reputation and employee satisfaction. Additionally, it will support personal growth and development through diverse volunteering experiences.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Financially Secure",
  //         levername: "Financial Health",
  //         description:
  //           "Finance Drives will provide employees with access to better financial wellbeing through targeted support in four key areas: insurance, savings, investment, and financial management. This initiative will include monthly workshops conducted by financial experts who will provide practical advice and actionable steps. Employees will also receive resource materials such as brochures, guides, and online resources to support their financial decisions. Additionally, one-on-one consultations with financial advisors will be available quarterly to offer personalized guidance based on individual financial needs.",
  //         outcome:
  //           "Employees will have better access to information and tools to improve their financial health, leading to reduced financial stress, increased financial security, and enhanced overall wellbeing.",
  //         duration: "6 month",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Become Financially Literate",
  //         levername: "Financial Health",
  //         description:
  //           "Awareness and Specialty Sessions will focus on improving employees' financial awareness and management skills through a series of comprehensive education sessions. These sessions will cover essential topics such as budgeting, saving, investing, and emergency fund preparation. Monthly group workshops will encourage interactive learning and peer support, while quarterly one-on-one sessions will provide personalized financial advice tailored to individual employees' needs. The content will be customized based on the average salary slabs within the company to ensure relevance and applicability. Practical exercises and real-life examples will be included to enhance learning outcomes.",
  //         outcome:
  //           "Employees will gain better financial management skills, be better prepared for financial emergencies, and improve their savings practices. This will lead to improved financial health, reduced financial stress, increased employee satisfaction, and higher retention rates.",
  //         duration: "6 months",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },

  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Recognised",
  //         levername: "Career Health",
  //         description:
  //           "Rewards & Recognition Program will focus on rewarding and recognising employees' efforts, behaviours, values, contributions, personal and professional achievements, and performances. This initiative will include monthly awards, spot bonuses, and public recognition in company meetings and newsletters. Employees will be nominated by their peers and managers, and a committee will evaluate the nominations to ensure fairness and consistency. Additional perks such as extra paid leave days, gift vouchers, and professional development opportunities will also be included.",
  //         outcome:
  //           "Employees will feel valued and appreciated, leading to increased motivation, job satisfaction, and loyalty. This will foster a positive work environment and enhance overall performance and productivity.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },

  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Gamified",
  //         levername: "Business Health",
  //         description:
  //           "Gamified Performance Programs proposes using gamification techniques to build a comprehensive performance program within the organisation. This initiative will involve designing interactive and engaging methods to track and enhance employee performance. Elements such as points, badges, leaderboards, and rewards will be incorporated to motivate employees and promote healthy competition. Regular feedback and performance reviews will be gamified to ensure continuous improvement and engagement.",
  //         outcome:
  //           "Employees will be more motivated and engaged, leading to improved performance, productivity, and job satisfaction.",
  //         duration: "6-12 months",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //       {
  //         initiativename: "Become Streamlined",
  //         levername: "Business Health",
  //         description:
  //           "Systems and Processes Enhancement proposes conducting comprehensive reviews of existing systems and processes, implementing enhancements to streamline workflows, and increasing overall efficiency. Additional training for management to improve accessibility. Regular benchmarking of benefits will also be included to ensure competitiveness and employee satisfaction.",
  //         outcome:
  //           "The initiative will result in smoother workflows, increased efficiency, improved satisfaction, and enhanced overall business performance. Employees will benefit from clearer processes, and a better resource allocation",
  //         duration: "Ongoing monitoring and adjustments",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "Medium",
  //       },
  //     ],
  //   },
  //   {
  //     lever: [
  //       {
  //         initiativename: "Become Advanced",
  //         levername: "Digital & Tech Health",
  //         description:
  //           "This initiative aims to enhance employees' physiological health by encouraging regular physical activity, increasing awareness of physical health, and providing resources and programs focused on well-being. The initiative will include fitness sessions with a physical trainer, health seminars, workplace movement challenges, medical checkups, and sports activities. Regular fitness sessions will be conducted by a professional trainer, covering various activities like yoga, strength training, and cardio exercises. Health seminars will raise awareness about the importance of physical health and provide tips on maintaining a healthy lifestyle. Workplace movement challenges and sports activities will encourage regular participation in physical activities.",
  //         outcome:
  //           "Improved physical health, higher energy levels, enhanced productivity, and better overall employee well-being. Employees will be more active and energetic, leading to a healthier, more productive workforce.",
  //         duration: "Ongoing",
  //         cost: "₹₹₹₹₹",
  //         impact: "High",
  //         effort: "High",
  //       },
  //     ],
  //   },
  // ];

  const scrollTabs = (direction) => {
    const scrollAmount = 200; // Adjust scroll amount as needed
    if (tabsRef.current) {
      tabsRef.current.scrollBy({
        left: direction === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      style={{
        position: "absolute",
        top: "50px",
        left: "18%",
        width: "65vw",
      }}
    >
      <div style={{ width: "60vw", backgroundColor: "#CFCFCF" }} />
      <p style={{ fontWeight: "510", fontSize: "20px" }}>Initiatives</p>
      <div
        style={{ width: "60vw", height: "1px", backgroundColor: "#CFCFCF" }}
      />

      {/* <button
          onClick={() => scrollTabs("left")}
          style={{
            backgroundColor: "#0042A6",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          &#8592; {/* Left arrow 
        </button> */}
      <div
        className="tabs"
        style={{
          width: "65vw",
          overflow: "auto",
        }}
        ref={tabsRef}
      >
        {content.map((tab, index) => (
          <div
            key={index}
            className={`tab-button ${activeTab === index ? "active" : ""}`}
            onClick={() => setActiveTab(index)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#0042A6",
              borderRadius: "8px",
              width: "200px",
              height: "20px",
              margin: "10px",
              color: `${activeTab === index ? "#fff06b" : "#fff"}`,
              whiteSpace: "nowrap",
            }}
          >
            <p style={{ width: "100%", fontSize: "14px" }}>
              {tab.lever[0].levername}
            </p>
          </div>
        ))}
      </div>
      <div
        style={{
          width: "65vw",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{ cursor: "pointer", marginRight: "15px" }}
          onClick={() => scrollTabs("left")}
        >
          <MdKeyboardArrowLeft size={30} color="#000" />
        </div>
        <div style={{ cursor: "pointer" }} onClick={() => scrollTabs("right")}>
          <MdKeyboardArrowRight size={30} color="#000" />
        </div>
      </div>

      {/* <button
          onClick={() => scrollTabs("right")}
          style={{
            backgroundColor: "#0042A6",
            color: "#fff",
            border: "none",
            borderRadius: "4px",
            padding: "10px",
            cursor: "pointer",
          }}
        >
          &#8594;
        </button> */}

      <div style={{ width: "62vw" }} className="tab-content">
        {content[activeTab].lever.map((lever, leverIndex) => (
          <div>
            {/* <div
                style={{
                    backgroundColor: "#fff06b",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                  top: "-20px",
                }}
              > */}
            <p
              style={{
                fontWeight: "700",
                fontSize: "25px",
                color: "#0042A6",
              }}
            >
              {lever.initiativename}
            </p>
            {/* </div> */}
            <p style={{ fontWeight: "700" }}>What we can do?</p>
            <p style={{ marginTop: "-15px" }}>{lever.description}</p>
            <p style={{ fontWeight: "700" }}>
              What can you expect as an outcome?
            </p>
            <p style={{ marginTop: "-15px" }}>{lever.outcome}</p>

            {/* <div className="grid-view">
                <div className="grid-item">Duration: {lever.duration}</div>
                <div className="grid-item">Cost: {lever.cost}</div>
                <div className="grid-item">Impact: {lever.impact}</div>
                <div className="grid-item">Effort: {lever.effort}</div>
              </div> */}
            {content[activeTab].lever.length !== 0 &&
              leverIndex !== content[activeTab].lever.length - 1 && (
                <div
                  style={{
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "rgba(0,0,0,0.2)",
                    }}
                  />
                </div>
              )}
          </div>
        ))}
      </div>
    </div>
  );
};
//{content[activeTab]}

export default Initiatives;
