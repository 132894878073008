import axios from "axios";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { HOST_NAME } from "./const";
import StatusBar from "./StatusBar";

const Report = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50px",
        left: "18%",
        width: "70vw",
        border: "1px solid rgba(0,0,0,0.3)",
        borderRadius: "10px",
        boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <iframe
        style={{ height: "70vh", width: "100%" }}
        loading="lazy"
        src="https://www.canva.com/design/DAGSfs5JzOc/eWjtyGTmcntClyh-XhaS2A/view?embed"
        allowfullscreen="allowfullscreen"
        allow="fullscreen"
      ></iframe>
    </div>
  );
};

export default Report;
