import React, { useRef, useEffect, useState } from "react";
import "./CSS/Dashboard.css";
import { BELOW_80, HOST_NAME } from "./const";
import { Doughnut, Bar } from "react-chartjs-2";
import { MdArrowBack } from "react-icons/md";
import { Chart, registerables } from "chart.js";
import axios from "axios";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Cookies from "js-cookie";
// import { GrAttachment } from "react-icons/gr";
Chart.register(...registerables);

const Dashboard = () => {
  const chartRefs = useRef([]);

  const [levers, setLevers] = useState([]);
  const [category, setCategory] = useState([]);
  const [isDashboard, setIsDashboard] = useState(true);
  const [leverDetailsIndex, setLeverDetailsIndex] = useState(null);

  const [inferences, setInferences] = useState({
    Respect: [
      {
        inf: "While some employees feel happy and secure in their roles, this score suggests that many employees may have concerns about job security or overall satisfaction with their roles.",
      },
      {
        inf: "Most employees feel that their workload is manageable, indicating that the company has been relatively successful in balancing task distribution and workload management.",
      },
      {
        inf: "Many employees feel that they lack close connections or friendships at work. This score highlights an issue with social support within the workplace, which can affect morale and mental Communication.",
      },
      {
        inf: "Employees feel a sense of guilt when taking leave, suggesting that the company culture may not fully support employees in taking time off for rest and recovery.",
      },
      {
        inf: "Many employees feel that it is difficult to take personal time off when working from home, indicating a blurred line between work and personal life.",
      },
      {
        inf: "Employees do not feel well-equipped with coping mechanisms to manage stress, suggesting that there may be insufficient Respect support or stress management resources available.",
      },
      {
        inf: "While some employees feel that the work environment is supportive, this score suggests that there is still room for improvement in maintaining a consistently positive and supportive atmosphere.",
      },
      {
        inf: "Employees do not feel that their voices are consistently heard, indicating that there may be barriers to open communication or limited opportunities for employee input.",
      },
    ],
    "Working Conditions": [
      {
        inf: "Employees feel significantly dissatisfied with their current salary in relation to their financial goals. This extremely low score suggests that employees believe their compensation is inadequate for their personal financial aspirations.",
      },
      {
        inf: "Many employees are not able to consistently contribute to emergency funds, indicating financial stress or constraints. This score suggests that employees may feel they are unable to save adequately for emergencies or unexpected situations.",
      },
      {
        inf: "While this score is closer to neutral, it reflects that a significant number of employees are the sole breadwinners in their households, which may contribute to financial pressure and insecurity.",
      },
      {
        inf: "Employees are divided on whether the company provides adequate support during times of financial need. While some may feel supported, others may believe that the company could do more to assist employees during challenging times.",
      },
      {
        inf: "Employees believe that the company does not offer sufficient long-term financial planning options, such as retirement savings plans or investment opportunities. This low score highlights a need for more robust financial schemes.",
      },
    ],

    Camaraderie: [
      {
        inf: "Employees have a strong understanding of the company’s Collaboration, indicating effective communication around organizational principles.",
      },
      {
        inf: "While employees understand the company’s Collaboration, there is a gap in how well these Collaboration are exhibited by their peers. This score indicates that employees may perceive a disconnect between stated Collaboration and everyday behavior.",
      },
      {
        inf: "Employees feel that the company’s Collaboration are somewhat reflected in the workplace, but there is room for improvement in how consistently these Collaboration are applied in decision-making and Support.",
      },
      {
        inf: "Employees feel that the company is not consistently creating meaningful experiences or moments that resonate with them. This score indicates a lack of Personal Jobful cultural Support.",
      },
      {
        inf: "Employees do not view the company’s people Support as particularly unique or impressive. This score suggests that employees believe there is room for improvement in how the company manages its people.",
      },
      {
        inf: "Employees generally believe the company operates ethically, which is a positive indicator of trust in the organization’s Justice and policies.",
      },
      {
        inf: "Many employees are not fully satisfied with the company’s ethical conduct policies, indicating that there may be concerns about how these policies are communicated or enforced.",
      },
      {
        inf: "While some employees feel that fairness is upheld, the score suggests that there are still concerns about whether all employees are treated equitably, regardless of background.",
      },
      {
        inf: "Employees feel that performance evaluations and rewards are not always fair, which indicates dissatisfaction with the company's evaluation and reward systems.",
      },
      {
        inf: "Employees do not feel that the company provides a comfortable or inviting space for social interactions, which can affect workplace camaraderie and community-building.",
      },
    ],
    Fairness: [
      {
        inf: "Employees are somewhat skeptical about the Justice’s ability to lead the company successfully. This score suggests that while some trust in Justice exists, there are concerns about the Justice's effectiveness in driving success.",
      },
      {
        inf: "Employees generally find management to be somewhat approachable, but there is room for improvement in making management more accessible and open to dialogue.",
      },
      {
        inf: "Employees believe that the company’s Equity is well-regarded externally, which is a strong indicator of Equity health and reputation.",
      },
      {
        inf: "While employees generally feel positively about the company, some are hesitant to recommend it to others. This indicates that there may be underlying concerns about the workplace environment or future prospects.",
      },
      {
        inf: "Employees recognize that the company’s Equity makes a meaningful Personal Job in the industry, but there is still room for greater influence.",
      },
      {
        inf: "While most employees are aware of the company’s Impartiality, there is still some uncertainty or lack of clarity around available perks and compensation packages.",
      },
      {
        inf: "Many employees do not find the existing perks and Impartiality compelling enough, which indicates dissatisfaction with the current compensation structure.",
      },
      {
        inf: "Employees find that accessing Impartiality is somewhat difficult or complicated, which may reduce the overall perceived value of these Impartiality.",
      },
      {
        inf: "Employees feel that the company’s metrics for measuring performance are inadequate or misaligned with the company’s goals. This low score indicates dissatisfaction with how success is tracked and evaluated.",
      },
      {
        inf: "Employees feel that the company’s systems and processes for executing strategies are somewhat lacking, which may hinder operational efficiency or performance.",
      },
      {
        inf: "Employees are not fully confident in management’s ability to hire and manage talent effectively. This score suggests that there may be issues with talent acquisition or employee management.",
      },
    ],

    Credibility: [
      {
        inf: "Many employees find it challenging to incorporate regular Competence into their workday. This indicates that employees may feel tied to their desks or unaware of the importance of taking breaks to move.",
      },
      {
        inf: "While most employees feel that their sleep quality is not significantly affecting their work, some still experience a lack of Integrity and focus, likely due to sleep-related issues.",
      },
      {
        inf: "A significant number of employees do not feel physically refreshed and rejuvenated after their workday, indicating potential burnout or exhaustion due to work demands.",
      },
      {
        inf: "Employees are not consistently taking conscious actions to prioritize their health and Communication, suggesting that they might not feel adequately motivated or supported to do so.",
      },
      {
        inf: "There is a gap in how much employees feel encouraged by the company to prioritize their health, signaling that existing wellness programs may not be effectively reaching or motivating the workforce.",
      },
    ],
    Pride: [
      {
        inf: "Employees feel that their work is somewhat draining and does not consistently energize them. This score suggests that many employees may experience fatigue or lack of Collaboration in their roles.",
      },
      {
        inf: "Most employees feel a strong sense of Team in their work. This is a significant strength for the organization, as employees who understand their Team are more likely to be engaged and motivated.",
      },
      {
        inf: "Employees are unsure about the company’s level of social responsibility, with many perceiving that the organization could do more to contribute to social and environmental causes.",
      },
      {
        inf: "Employees feel they do not have enough opportunities to make a meaningful Personal Job through their work. This low score highlights a disconnect between employees and the sense of making a tangible difference.",
      },
      {
        inf: "While some employees feel their work is fulfilling, there is still a significant portion that does not feel their work makes a meaningful Personal Job on others.",
      },
      {
        inf: "Many employees are incorporating mindfulness Support into their workday, but some may not have fully embraced these strategies or feel they lack the time or support to do so.",
      },
    ],
  });
  const [insights, setInsights] = useState({
    Credibility: {
      top3depts: [
        {
          ins: "E-City        (15 | 93%)",
        },
        {
          ins: "Technical Services - Video ARC        ( 6 | 91%)",
        },
        {
          ins: "Justickets        (27 | 88%)",
        },
      ],
      bottom3depts: [
        {
          ins: "Corporate - IT        (9 | 67%)",
        },
        {
          ins: "IA - Sales        ( 8 | 58%)",
        },
        {
          ins: "QCN - Operations        (10 | 45%)",
        },
      ],
      top3desgn: [
        {
          ins: "Executive - Operations        (6  | 100%)",
        },
        {
          ins: "Deputy Manager - Finance & Accounts       (6  | 93%)",
        },
        {
          ins: "Assistant - Technical Services (Qube - CDP)       (6| 91%)",
        },
      ],
      bottom3desgn: [
        {
          ins: "Executive - Operations (Qube)       (9 | 65%)",
        },
        {
          ins: "Team Lead - Mastering Services (Content Duplication & Backup)        ( 6 | 61%)",
        },
        {
          ins: "Senior Engineer - Mastering Services (Films)        ( 8 | 57%)",
        },
      ],
    },
    Pride: {
      top3depts: [
        {
          ins: "Technical Services - Video ARC  ( 6 | 94%)",
        },
        {
          ins: "QW - Operation ( 7 | 92%)",
        },
        {
          ins: "QCN - Sales ( 59  | 90%)",
        },
      ],
      bottom3depts: [
        {
          ins: "QW - Customer Support  (25| 73%)",
        },
        {
          ins: "QCN - Sales Coordination  (7 | 69%)",
        },
        {
          ins: "QCN - Operations (10 | 55%)",
        },
      ],
      top3desgn: [
        {
          ins: "Junior Software Engineer  (6 | 100%)",
        },
        {
          ins: "Manager - Technical Services (Qube)  ( 11 | 96%)",
        },
        {
          ins: "Assistant - Technical Services (Qube - CDP)  ( 6 | 93%)",
        },
      ],
      bottom3desgn: [
        {
          ins: "Technical Specialist  (9 | 72%)",
        },
        {
          ins: "Content Editor (9 | 68%)",
        },
        {
          ins: "Senior Engineer - Mastering Services (Films) (8 | 64%)",
        },
      ],
    },
    "Working Conditions": {
      top3depts: [
        {
          ins: "Justickets - (94%)",
        },
        {
          ins: "MS Project Management & Support (94%)",
        },
        {
          ins: "Technical Services - Video ARC (92%)",
        },
      ],
      bottom3depts: [
        {
          ins: "Corporate - IT (78%)",
        },
        {
          ins: "Qube - Subtitle (78%)",
        },
        {
          ins: "QCN - Operations (63%)",
        },
      ],
      top3desgn: [
        {
          ins: "",
        },
        {
          ins: "",
        },
        {
          ins: "",
        },
      ],
      bottom3desgn: [
        {
          ins: "",
        },
        {
          ins: "",
        },
        {
          ins: "",
        },
      ],
    },

    Fairness: {
      top3depts: [
        {
          ins: "QW - Operation (7| 97%)",
        },
        {
          ins: "Justickets (27 | 90%)",
        },
        {
          ins: "Technical Services - Video ARC (6 | 90%)",
        },
      ],
      bottom3depts: [
        {
          ins: "QW - Customer Support  (25 | 70%)",
        },
        {
          ins: "QCN - Field support & CT  ( 7 | 69%)",
        },
        {
          ins: "QCN - Operations (10 | 24%)",
        },
      ],
      top3desgn: [
        {
          ins: "Executive - Operations  ( 6  | 100%)",
        },
        {
          ins: "Assistant General Manager - Sales (QCN)  ( 7 | 97%)",
        },
        {
          ins: "Technical Specialist  ( 9 | 93%)",
        },
      ],
      bottom3desgn: [
        {
          ins: "Engineer - Technical Services (Qube - Ingest)  (16 | 63%)",
        },
        {
          ins: "Senior Executive - Operations (Qube)  (10 | 62%)",
        },
        {
          ins: "Senior Engineer - Mastering Services (Films) (8 | 43%)",
        },
      ],
    },
    Camaraderie: {
      top3depts: [
        {
          ins: "Technical Services - Video ARC ( 6 | 94%)",
        },
        {
          ins: "Justickets ( 27 | 91%)",
        },
        {
          ins: "E-City (15 | 98%)",
        },
      ],
      bottom3depts: [
        {
          ins: "Qube - Subtitle  (15 | 61%)",
        },
        {
          ins: "QCN - Operations  (10 | 53%)",
        },
        {
          ins: "QW - Customer Support (25 | 53%)",
        },
      ],
      top3desgn: [
        {
          ins: "Executive - Operations (6  | 92%)",
        },
        {
          ins: "Junior Software Engineer (6  | 92%)",
        },
        {
          ins: "Office Assistant (25| 92%)",
        },
      ],
      bottom3desgn: [
        {
          ins: "Senior Engineer - Mastering Services (Films) (8 | 58%)",
        },
        {
          ins: "Assistant Manager - Mastering Services (Films)  ( 7 | 57%)",
        },
        {
          ins: "Senior Manager - Sales (QCN) ( 10 | 54%)",
        },
      ],
    },

    Respect: {
      top3depts: [
        {
          ins: "Technical Services - Video ARC  ( 6  | 93%)",
        },
        {
          ins: "E-City  (15 | 89%)",
        },
        {
          ins: "Justickets (27 | 88%)",
        },
      ],
      bottom3depts: [
        {
          ins: "Corporate - IT ( 9 | 63%)",
        },
        {
          ins: "QM - Mastering/ CD (46 | 62%)",
        },
        {
          ins: "QCN - Operations (10 | 43%)",
        },
      ],
      top3desgn: [
        {
          ins: "Executive - Operations  ( 6 | 100%)",
        },
        {
          ins: "Assistant General Manager - Sales (QCN)  ( 7 | 90%)",
        },
        {
          ins: "Deputy Manager - Finance & Accounts  ( 6 | 87%)",
        },
      ],
      bottom3desgn: [
        {
          ins: "Senior Engineer - Mastering Services (Films)  (8 | 59%)",
        },
        {
          ins: "Team Lead - Mastering Services (Content Duplication & Backup)  (6 | 58%)",
        },
        {
          ins: "Senior Manager - Sales (QCN) (10 | 48%)",
        },
      ],
    },
  });

  const [customerName, setCustomerName] = useState();

  const createGradient = (ctx, progress) => {
    const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    gradient.addColorStop(progress, "#CCE0FF");
    gradient.addColorStop(0, "#2860B5");
    return gradient;
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${HOST_NAME}/survey/get-health-score`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setLevers(res.data.levers);
          console.log(res.data.category);
          setCategory(res.data.category);
        })
        .catch((err) => console.log(err));
    };

    const fetchInference = async () => {
      await axios
        .get(`${HOST_NAME}/survey/get-inferences`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setInferences(res.data.data.levers);
          console.log("Inferences", res.data.data);
        })
        .catch((err) => console.log(err));
    };

    const fetchInsights = async () => {
      await axios
        .get(`${HOST_NAME}/survey/get-insights`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          setInsights(res.data.data.levers);
        })
        .catch((err) => console.log(err));
    };

    const fetchCustomer = async () => {
      await axios
        .get(`${HOST_NAME}/survey/get-customer`, {
          method: "GET",
          headers: {
            token: Cookies.get("token"),
          },
        })
        .then((res) => {
          console.log(res.data.data);
          setCustomerName(res.data.data.name);
        })
        .catch((err) => console.log(err));
    };

    // fetchInference();
    // fetchInsights();
    fetchCustomer();
    fetchData();

    Object.keys(levers).forEach((key, index) => {
      const chartRef = chartRefs.current[index];
      if (chartRef) {
        const ctx = chartRef.getContext("2d");
        const score = levers[key];
        const data = [score, 100 - score];
        const total = data.reduce((acc, value) => acc + value, 0);
        const progress = data[0] / total;
        const gradient = createGradient(ctx, progress);

        new Chart(ctx, {
          type: "doughnut", // Example chart type
          data: {
            datasets: [
              {
                data,
                backgroundColor: [
                  gradient,
                  "rgba(200, 200, 200, 0.5)", // Background color for the remaining segment
                ],
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
          },
        });
      }
    });

    // if (chart) {
    //   const ctx = chart.ctx;

    //   const dataset = chart.data.datasets[0];
    //   const total = dataset.data.reduce((acc, value) => acc + value, 0);
    //   const progress = dataset.data[0] / total;

    //   const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    //   gradient.addColorStop(progress, "#CCE0FF");
    //   //   gradient.addColorStop(progress, "#2860B5");
    //   //   gradient.addColorStop(progress, "#2860B5");
    //   gradient.addColorStop(0, "#2860B5");
    //   //   gradient.addColorStop(1, "#2860B5");

    //   dataset.backgroundColor = [
    //     gradient,
    //     "rgba(200, 200, 200, 0.5)", // Background color for the remaining segment
    //   ];

    //   chart.update();
    // }
    // if (chart1) {
    //   const ctx = chart1.ctx;

    //   const dataset = chart1.data.datasets[0];
    //   const total = dataset.data.reduce((acc, value) => acc + value, 0);
    //   const progress = dataset.data[0] / total;

    //   const gradient = ctx.createLinearGradient(0, 0, ctx.canvas.width, 0);
    //   gradient.addColorStop(progress, "#CCE0FF");
    //   //   gradient.addColorStop(progress, "#2860B5");
    //   //   gradient.addColorStop(progress, "#2860B5");
    //   gradient.addColorStop(0, "#2860B5");
    //   //   gradient.addColorStop(1, "#2860B5");

    //   dataset.backgroundColor = [
    //     gradient,
    //     "rgba(200, 200, 200, 0.5)", // Background color for the remaining segment
    //   ];

    //   chart1.update();
    // }
  }, []);

  const physiological = {
    datasets: [
      {
        data: [levers["Credibility"], 100 - levers["Credibility"]],
        backgroundColor: [
          levers["Credibility"] < 80 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const mental = {
    datasets: [
      {
        data: [levers["Respect"], 100 - levers["Respect"]],
        backgroundColor: [
          levers["Respect"] < 80 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };

  const financial = {
    datasets: [
      {
        data: [
          levers["Working Conditions"],
          100 - levers["Working Conditions"],
        ],
        backgroundColor: [
          levers["Working Conditions"] < 80 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const cultural = {
    datasets: [
      {
        data: [levers["Camaraderie"], 100 - levers["Camaraderie"]],
        backgroundColor: [
          levers["Camaraderie"] < 80 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };
  const spiritual = {
    datasets: [
      {
        data: [levers["Pride"], 100 - levers["Pride"]],
        backgroundColor: [levers["Pride"] < 80 ? BELOW_80 : "#278AFF", "#ccc"],
      },
    ],
  };

  const business = {
    datasets: [
      {
        data: [levers["Fairness"], 100 - levers["Fairness"]],
        backgroundColor: [
          levers["Fairness"] < 80 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
      },
    ],
  };

  const sum = (arr) => {
    return arr.reduce((a, b) => a + b, 0);
  };

  const LeverDetail = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const leverDetails = [
      {
        overallscore: levers["Credibility"],
        overallscoredata: [levers["Credibility"], 100 - levers["Credibility"]],
        backgroundcolor: [
          levers["Credibility"] < 80 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Credibility",
        labeldata: ["Communication", "Competence", "Integrity"],
        labelscore: [
          category["Communication"],
          category["Competence"],
          category["Integrity"],
        ],
        desc: "Credibility assesses if people take up an active role in enhancing their physical Communication for optimal performance and productivity",
      },
      {
        overallscore: levers["Respect"],
        overallscoredata: [levers["Respect"], 100 - levers["Respect"]],
        backgroundcolor: [
          levers["Respect"] < 80 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Respect",
        labeldata: ["Collaboration", "Support", "Caring"],
        labelscore: [
          category["Collaboration"],
          category["Support"],
          category["Caring"],
        ],
        desc: "Respect examines if the workplace fosters emotional safety, ease of Caring, and satisfaction for robust Respect.",
      },
      {
        overallscore: levers["Fairness"],
        overallscoredata: [levers["Fairness"], 100 - levers["Fairness"]],
        backgroundcolor: [
          levers["Fairness"] < 80 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Fairness",
        labeldata: ["Justice", "Equity", "Impartiality"],
        labelscore: [
          category["Justice"],
          category["Equity"],
          category["Impartiality"],
        ],
        desc: "Fairness understands the perceived organizational value through Equity resonance, Justice and Personal Job creation.",
      },

      {
        overallscore: levers["Working Conditions"],
        overallscoredata: [
          levers["Working Conditions"],
          100 - levers["Working Conditions"],
        ],
        backgroundcolor: [
          levers["Working Conditions"] < 80 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Working Conditions",
        labeldata: [""],
        labelscore: [0],
        desc: "Working Conditions examines employees' financial awareness of the Working Conditions and efforts towards economic stability.",
      },

      {
        overallscore: levers["Camaraderie"],
        overallscoredata: [levers["Camaraderie"], 100 - levers["Camaraderie"]],
        backgroundcolor: [
          levers["Camaraderie"] < 80 ? BELOW_80 : "#278AFF",
          "#ccc",
        ],
        levername: "Camaraderie",
        labeldata: ["Intimacy", "Community", "Hospitality"],
        labelscore: [
          category["Intimacy"],
          category["Community"],
          category["Hospitality"],
        ],
        desc: "Camaraderie explores how the Support and Collaboration followed are unique, bragworthy, and effective in crafting a great experience.",
      },

      {
        overallscore: levers["Pride"],
        overallscoredata: [levers["Pride"], 100 - levers["Pride"]],
        backgroundcolor: [levers["Pride"] < 80 ? BELOW_80 : "#278AFF", "#ccc"],
        levername: "Pride",
        labeldata: ["Team", "Personal Job", "Corporate Image"],
        labelscore: [
          category["Team"],
          category["Personal Job"],
          category["Corporate Image"],
        ],
        desc: "Pride assesses the alignment of individual Team and their inclination to a larger societal mission.",
      },
    ];

    const data = {
      datasets: [
        {
          data: leverDetails[leverDetailsIndex].overallscoredata,
          backgroundColor: leverDetails[leverDetailsIndex].backgroundcolor,
        },
      ],
    };

    const bardata = {
      labels: leverDetails[leverDetailsIndex].labeldata,
      datasets: [
        {
          backgroundColor: leverDetails[leverDetailsIndex].labelscore.map(
            (score) => (score < 80 ? BELOW_80 : "#278AFF")
          ),
          data: leverDetails[leverDetailsIndex].labelscore,
        },
      ],
    };

    const options = {
      maintainAspectRatio: false,
      layout: {
        padding: 0,
      },
      // plugins: {
      //   legend: {
      //     display: false,
      //   },
      // },
      plugins: {
        datalabels: {
          display: true,
          color: "black",
          align: "top",
          padding: {
            right: 2,
          },

          labels: {
            padding: { top: 10 },
            title: {
              font: {
                weight: "bold",
              },
            },
            value: {
              color: leverDetails[leverDetailsIndex].labelscore.map((score) => {
                return score < 80 ? "#278AFF" : BELOW_80;
              }),
            },
          },
          formatter: function (value) {
            return "\n" + value + "%";
          },
        },

        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              label += context.parsed.y + "%";
              return label;
            },
          },
        },
        legend: {
          display: false,
        },
      },

      scales: {
        x: {
          display: true,
          grid: {
            display: false,
          },
        },
        y: {
          display: false,
        },
      },
    };

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "80vw",
        }}
      >
        <MdArrowBack
          onClick={() => setIsDashboard(true)}
          style={{
            cursor: "pointer",
            width: "30px",
            height: "30px",
          }}
          size={25}
        />
        {/* <div
            style={{ width: "13px", height: "3px", backgroundColor: "#000" }}
          />
          <div
            style={{
              width: "7px",
              height: "3px",
              backgroundColor: "#000",
              transform: "rotate(45deg)",
              position: "relative",
            }}
          />
          <div
            style={{
              width: "7px",
              height: "3px",
              backgroundColor: "#000",
              transform: "rotate(-45deg)",
              position: "relative",
              top: "-10px",
            }}
          /> */}
        <div
          style={{
            width: "60vw",
            height: "30%",
            boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "20px",
            paddingLeft: "20px",
            paddingTop: "0px",
            paddingBottom: "20px",
            marginTop: "20px",
          }}
        >
          <p
            style={{
              fontWeight: "600",
              fontSize: "24px",
              color: "#30373E",
              marginLeft: "10px",
            }}
          >
            {leverDetails[leverDetailsIndex].levername}
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "60vw",
              marginTop: "-20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginTop: "5%",
              }}
            >
              <div style={{ width: "150px", height: "150px" }}>
                <Doughnut
                  data={data}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "18px",
                    color: "#2961B6",
                    marginTop: "-85px",
                    marginLeft: "60px",
                  }}
                >
                  {leverDetails[leverDetailsIndex].overallscore}%
                </p>
              </div>
              <p
                style={{
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "rgba(48, 55, 62, 0.7)",
                  width: "20vw",
                }}
              >
                {leverDetails[leverDetailsIndex].desc}
              </p>
            </div>

            <div>
              {bardata.datasets[0].data.length !== 1 && (
                <Bar
                  style={{ height: "200px", width: "300px" }}
                  data={bardata}
                  plugins={[ChartDataLabels]}
                  options={options}
                />
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "61.5vw",
            justifyContent: "space-around",
            marginTop: "5%",
            marginBottom: "5%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              paddingTop: "0px",
              paddingBottom: "20px",
              marginRight: "30px",
            }}
          >
            <p
              style={{ fontWeight: "600", fontSize: "24px", color: "#30373E" }}
            >
              Inferences
            </p>
            <div
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "rgba(48, 55, 62, 0.7)",
              }}
            >
              <ul>
                {inferences[leverDetails[leverDetailsIndex].levername].map(
                  (inf) => {
                    return <li>{inf["inf"]}</li>;
                  }
                )}
              </ul>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginLeft: "15px",
              boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.2)",
              borderRadius: "20px",
              paddingRight: "20px",
              paddingLeft: "20px",
              paddingTop: "0px",
              paddingBottom: "20px",
            }}
          >
            <p
              style={{ fontWeight: "600", fontSize: "24px", color: "#30373E" }}
            >
              Top 3 vs Bottom 3
            </p>
            <div
              style={{
                fontWeight: "400",
                fontSize: "16px",
                color: "rgba(48, 55, 62, 0.7)",
              }}
            >
              <p style={{ fontWeight: "700", color: "black" }}>
                Top 3 Departments
              </p>
              <ul>
                {insights[
                  leverDetails[leverDetailsIndex].levername
                ].top3depts.map((ins) => {
                  return <li style={{ color: "green" }}>{ins["ins"]}</li>;
                })}
              </ul>
              <p style={{ fontWeight: "700", color: "black" }}>
                Bottom 3 Departments
              </p>
              <ul>
                {insights[
                  leverDetails[leverDetailsIndex].levername
                ].bottom3depts.map((ins) => {
                  return <li style={{ color: "red" }}>{ins["ins"]}</li>;
                })}
              </ul>
              <p style={{ fontWeight: "700", color: "black" }}>
                Top 3 Designations
              </p>
              <ul>
                {insights[
                  leverDetails[leverDetailsIndex].levername
                ].top3desgn.map((ins) => {
                  return <li style={{ color: "green" }}>{ins["ins"]}</li>;
                })}
              </ul>
              <p style={{ fontWeight: "700", color: "black" }}>
                Bottom 3 Designations
              </p>
              <ul>
                {insights[
                  leverDetails[leverDetailsIndex].levername
                ].bottom3desgn.map((ins) => {
                  return <li style={{ color: "red" }}>{ins["ins"]}</li>;
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DashboardLever = () => {
    return (
      <div>
        <p
          style={{
            color: "#30373E",
            fontSize: "24px",
            fontWeight: "700",
            width: "90%",
          }}
        >
          Dashboard
        </p>
        <div className="welcome-note">
          <div
            style={{
              width: "100%",
              height: "100%",
              backgroundColor:
                "linear-gradient(to right,rgba(0, 66, 166, 0.2),rgba(0, 66, 166, 1))",
              padding: "20px",
            }}
          >
            <p className="welcome-title">Hello {customerName}!</p>
            <p className="welcome-content">
              <span>
                Welcome to your Reflections Survey Dashboard! This space is
                designed to give you a clear, comprehensive view of your
                organization’s culture, highlighting how employees feel about
                key areas like Credibility, Respect, Fairness, Pride,
                Camaraderie, and Working Conditions.
              </span>
              <span>
                Explore these insights to understand what drives positive
                experiences for your team and where there may be opportunities
                for growth. Each section provides detailed feedback, enabling
                you to make informed decisions that support a more connected,
                respectful, and fulfilling workplace.
              </span>
              <span>
                We hope this dashboard helps you create an environment that
                resonates with your team’s values and aspirations, fostering a
                workplace where everyone feels valued and empowered.
              </span>
              {/* <span>
                You are one step closer to making Become a thriving workplace
              </span> */}
            </p>
          </div>
          {/* <div style={{ width: "80%" }}>
            <img
              style={{ width: "100%", height: "100%" }}
              src={require("../assets/welcome-card.png")}
            />
          </div> */}
        </div>
        <p
          style={{
            color: "#30373E",
            fontSize: "24px",
            fontWeight: "600",
            width: "90%",
          }}
        >
          Trust indicators
        </p>
        <div className="lever-container">
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(0);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/physiological-health-icon.png")}
                />
              </div>
              <p className="lever-name">Credibility</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={physiological}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Credibility"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Communication"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Communication</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Competence"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Competence</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Integrity"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Integrity</p>
                </div>
              </div>
            </div>
            <p>
              Credibility assesses if people take up an active role in enhancing
              their physical Communication for optimal performance and
              productivity
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(1);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/mental-health-icon.png")}
                />
              </div>
              <p className="lever-name">Respect</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={mental}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Respect"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Collaboration"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Collaboration</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Support"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Support</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Caring"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Caring</p>
                </div>
              </div>
            </div>
            <p>
              Respect examines if the workplace fosters emotional safety, ease
              of Caring, and satisfaction for robust Respect
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(2);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/business-health-icon.png")}
                />
              </div>
              <p className="lever-name">Fairness</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={business}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Fairness"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "-40px",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Justice"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Justice</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Equity"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Equity</p>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Impartiality"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Impartiality</p>
                </div>
              </div>
            </div>
            <p>
              Fairness understands the perceived organizational value through
              Equity resonance, Justice and Personal Job creation.
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(3);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/financial-health-icon.png")}
                />
              </div>
              <p className="lever-name">Working Conditions</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={financial}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Working Conditions"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Working Conditions"] < 75
                          ? BELOW_80
                          : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Working Conditions</p>
                </div>
              </div>
            </div>
            <p>
              Working Conditions examines employees' financial awareness of the
              Working Conditions and efforts towards economic stability.
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(4);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/cultural-health-icon.png")}
                />
              </div>
              <p className="lever-name">Camaraderie</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={cultural}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Camaraderie"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "-40px",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Collaboration"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Intimacy</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Support"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Community</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Caring"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Hospitality</p>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Justice"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Justice</p>
                </div> */}
              </div>
            </div>
            <p>
              Camaraderie explores how the Support and Collaboration followed
              are unique, bragworthy, and effective in crafting a great
              experience
            </p>
          </div>
          <div
            className="lever-card"
            onClick={() => {
              setIsDashboard(false);
              setLeverDetailsIndex(5);
            }}
          >
            <div className="lever-header">
              <div className="lever-icon">
                <img
                  style={{ width: "40px", height: "40px" }}
                  src={require("../assets/spiritual-health-icon.png")}
                />
              </div>
              <p className="lever-name">Pride</p>
            </div>
            <div className="lever-graph">
              <div style={{ width: "180px", height: "180px" }}>
                <Doughnut
                  data={spiritual}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                      },
                    },
                    rotation: 0,
                    circumference: 360,
                    cutout: "70%",
                    maintainAspectRatio: true,
                    responsive: true,
                  }}
                />
                <p
                  style={{
                    position: "relative",
                    left: "70px",
                    top: "-130px",
                    color: "#30373E",
                  }}
                >
                  Score
                </p>
                <p
                  style={{
                    position: "relative",
                    left: "75px",
                    top: "-140px",
                    color: "#30373E",
                  }}
                >
                  {levers["Pride"]}%
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Team"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Team</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Personal Job"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Personal Job</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    className="category-point"
                    style={{
                      backgroundColor:
                        category["Corporate Image"] < 80 ? BELOW_80 : "#278AFF",
                    }}
                  />
                  <p style={{ marginLeft: "10px" }}>Corporate Image</p>
                </div>
              </div>
            </div>
            <p>
              Pride assesses the alignment of individual Team and their
              inclination to a larger societal mission
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-container">
      {isDashboard && <DashboardLever />}
      {!isDashboard && <LeverDetail />}
    </div>
  );
};

export default Dashboard;
